import React from "react"

import Index from "../../components/index"
import Seo from "../../components/other/seo"
import PageHeader from "../../components/elements/PageHeader";
import Container from "../../components/layout/Container";

const SeniorVicePresidentOperations = () => (
  <Index>
    <Seo title="Senior Vice President, Operations" />
    <PageHeader extraClasses="auto-height">
      <h1>Join our Team</h1>
    </PageHeader>
    <Container extraContainerClasses="text-gray space-out">
      <h2 className="text-blue">Senior Vice President, Operations</h2>
    </Container>
    <Container extraContainerClasses="text-gray space-out">
      <h2 className="text-blue">Position Summary</h2>
      <p>Lead the operational execution and evolution of InCircle, with a focus on delivering a quality service and excellent client experience. Manage day to day operations of InCircle, with an emphasis on developing the data extraction team to ensure clients are receiving all the service needed to be successful.</p>
    </Container>
    <Container extraContainerClasses="text-gray">
      <h2 className="text-blue">Essential Functions and Duties</h2>
      <ul>
        <li>Provide continual operational support to clients from onboarding through account management, including quarterly QBRs.</li>
        <li>Development of training and implementation materials and lead the training and implementation efforts for clients on the InCircle software.</li>
        <li>Liaise with Physicians supplying review services to InCircle assuring staff is available to assure meeting client deadlines.</li>
        <li>Assist EVP with subject matter expertise when identifying and developing relationships with potential clients.</li>
        <li>Coordinate efforts of all cross functional stakeholders to consistently improve service levels inclusion of advancement of technology to become more efficient through automated processes.</li>
        <li>Manage and supervise subordinate staff to ensure efficient and profitable daily operations while maintaining high quality of service to clients.</li>
        <li>Provides timely and specific feedback to subordinate staff in a constructive manner; clearly explains the desired results when assigning tasks; holds employees accountable; supports employee development through training.</li>
        <li>Maintain division in compliance with pertinent regulatory and accrediting agencies.</li>
        <li>Responsible for coordinating an annual financial operating budget with EVP. Identifies and implements cost reduction measures without detracting from service, operational or quality level.</li>
        <li>Supports and reinforces FFF’s vision; inspires trust; develops the willingness in others to work toward common objectives, inspires people to look at options; delegates the appropriate amount of work with the proper authority to get the job done; achieves organizational and/or operational results through people.</li>
        <li>Continuously strive to improve processes, service and quality to internal and external customers, and employee relations.</li>
        <li>Comply with the policies and procedures stated in the Injury and Illness Prevention Program by always working in a safe manner and immediately reporting any injury, safety hazard, or program violation.</li>
        <li>Ensure conduct is consistent with all Compliance Program Policies and procedures when engaging in any activity on behalf of the Company. Immediately reports any concerns or violations.</li>
        <li>Displays dedication to the position responsibilities and achieve assigned goals and objectives.</li>
        <li>Support the Company’s Compliance and Safety programs by ensuring compliance with program policies and procedures. Lead by example.</li>
        <li>Other duties as assigned.</li>
      </ul>
      <h3>Dimension</h3>
      <p>Annual sales > start up.</p>
      <h3>Supervisory Responsibilities</h3>
      <ul>
        <li>Define subordinates’ duties, goals and objectives in alignment with the Company’s strategic objectives and ensure subordinates successfully complete assignments.</li>
        <li>Carry out supervisory responsibilities in accordance with the Company’s policies and applicable laws.</li>
        <li>Responsibilities include interview, hire, and develop employees; Plan, assign, and direct work; Appraising performance; Rewarding and disciplining employees; Addressing complaints and resolving problems.</li>
        <li>Develop employees’ capabilities by ensuring their participation in Company training programs; Delegate to employees’ strengths; Provide adequate instructions when assigning tasks or goals; Provide positive and negative feedback when appropriate.</li>
      </ul>
    </Container>
    <Container extraContainerClasses="text-gray">
      <h2 className="text-blue">Education, Knowledge, Skills and Experience</h2>
      <h3>Required Education</h3>
      <p>Clinician preferred (Pharm D or RPh)</p>
      <h3>Required Knowledge and Skills</h3>
      <ul>
        <li>Specific knowledge of the home infusion industry, managed care utilization management programs,) including pharmacy, nursing, reimbursement, managed care, sales.</li>
        <li>Prior experience working in a startup environment.</li>
        <li>Experience with multitasking across a matrixed organization to implement
          programs.</li>
        <li>Ability to prioritize and track multiple projects to ensure deadlines are met.</li>
        <li>Ability to participate on and work as an integral member of a team.</li>
        <li>Ability to exercise discretion and maintain confidentiality to the level of
          required HIPAA standards.</li>
        <li>Ability to analyze current business process for improvement opportunities, develop strategy around new product opportunities, and develop action plan for change and implement change.</li>
        <li>Work collaboratively with EVP and Sales to develop the strategic roadmap to grow the division.</li>
        <li>Previous P&L accountability.</li>
        <li>Represent the Company in a professional manner and appearance at all
          times.</li>
        <li>Understand and internalize the Company’s purpose, Display loyalty to the Company and its organizational values.</li>
        <li>Display enthusiasm and dedication to learning how to be more effective on the job and share knowledge with others.</li>
        <li>Work effectively with co-workers, internal and external customers and others by sharing ideas in a constructive and positive manner; listen to and objectively consider ideas and suggestions from others; keep commitments; keep others informed of work progress, timetables, and issues; address problems and issues constructively to find mutually acceptable and practical business solutions; address others by name, title, or other respectful identifier, and; respect the diversity of our work force in actions, words, and deeds.</li>
        <li>Encourage teamwork by treating all subordinates fairly; keep employees informed of department/Company plans; Encourage group participation; Value employees’ input; Acknowledge achievements; Lead by example.</li>
      </ul>
      <h3>Required Experience</h3>
      <ul>
        <li>Ten years managed care experience</li>
        <li>Five years home infusion experience</li>
        <li>Five years management experience</li>
      </ul>
    </Container>
    <Container extraContainerClasses="text-gray">
      <h2 className="text-blue">Physical requirements</h2>
      <p>Vision, hearing, speech, movements requiring the use of wrists, hands and/or fingers. Must have the ability to view a computer screen for long periods and the ability to sit for extended periods. Must have the ability to work the hours and days required to complete the essential functions of the position, as scheduled. The employee occasionally lifts up to 20 lbs. and occasionally kneels and bends. Must have the ability to travel occasionally. Working condition include normal office setting.</p>
    </Container>
    <Container extraContainerClasses="text-gray">
      <h2 className="text-blue">Mental Demands</h2>
      <p>Learning, thinking, concentration and the ability to work under pressure, particularly during busy times. Must be able to pay close attention to detail and be able to work as a member of a team to ensure excellent customer service. Must have the ability to interact effectively with co-workers and customers, and exercise self-control and diplomacy in customer and employee relations’ situations. Must have the ability to exercise discretion as well as appropriate judgments when necessary. Must be proactive in finding solutions.</p>
    </Container>
    <Container extraContainerClasses="text-gray">
      <h2 className="text-blue">Direct Reports</h2>
      <ul>
        <li>Data Extractors</li>
        <li>Data Scientists</li>
        <li>Consultants working on software.</li>
        <li>MDs providing oversight.</li>
      </ul>
    </Container>
    <Container extraContainerClasses="text-gray">
      <h2 className="text-blue">EEO/AAP Statement</h2>
      <p>FFF Enterprises is an EEO/AAP employer; all business decisions (employment, promotions, compensation, etc.) are made without regard to race, color, religion, creed, gender, national origin, age, disability, marital or veteran status, sexual orientation, gender identity, or any other legally protected status. FFF Enterprises promotes an equal employment opportunity workplace which includes reasonable accommodation consideration of otherwise qualified disabled.</p>
    </Container>
    <Container extraContainerClasses="text-gray">
      <h2 className="text-blue">Acknowledgement</h2>
      <p>The above statements are intended to describe the general nature and level of work being performed by the incumbent assigned to this classification. They are not intended to be construed as an exhaustive list of all responsibilities, duties, and/or skills required of all personnel so classified.</p>
      <p>&nbsp;</p>
      <p className="align-center">
        <a href="mailto:resumes@fffenterprises.com" className="button primary">Apply for this position</a>
      </p>
      <p>&nbsp;</p>
    </Container>
  </Index>
)

export default SeniorVicePresidentOperations
